import Shine from "@ui/Shine"
import React, { useEffect, useRef, useState } from "react"

import "./Pack.scss"

interface PackProps {
  open?: boolean
  size: number
  shine?: {
    durationMillis: number
    delayMillis: number
  }
}

const PACK_RATIO = 1.243

const _Pack: React.FC<PackProps> = ({ open, size, shine }) => {
  const [ initiallyOpen ] = useState(!!open)

  const width = Math.round(size / PACK_RATIO)
  const height = size

  const className = [
    "neo__pack",
    open ? "neo__pack--open" : "neo__pack--unopened",
    initiallyOpen ? "neo__pack--initiallyOpen" : ""
  ].join(" ")

  return <div className={className} style={{width: `${width}px`, height: `${height}px`}}>
    {shine && <Shine durationMillis={shine.durationMillis} delayMillis={shine.delayMillis} />}
    <div className="neo__pack__img" />
    <div className="neo__pack__img__opened" />
    <div className="neo__pack__img__rip" />
  </div>
}

const Pack: React.FC<PackProps> = ({ open, size, shine }) => {
  // remount when was open onece and now no longer is
  const [ remountKey, setRemountKey ] = useState(0)
  const wasOpenOnceRef = useRef(!!open)
  if (open) { wasOpenOnceRef.current = true }

  useEffect(() => {
    if (!open && wasOpenOnceRef.current) {
      setRemountKey((old: number) => old + 1)
    }
  }, [!!open])

  return <_Pack key={remountKey} open={open} size={size} shine={shine} />
}

export default Pack
